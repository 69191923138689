<template>
  <div>
    <table-page :config="config" />
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { strategys, informations, strategyInfos } from '@/utils/public';

export default {
  name: "serviceItems",
  components: {
    TablePage
  },
  data() {
    return {
      config: {
        /* 表格是否可多选 */
        dataUrl: '/article/list?orgShopId=' + this.$route.query.id,
        belongTo: '当前机构：' + this.$route.query.other,
        filter: {
          controls: [
            {
              key: 'title',
              label: '标题',
              type: 'text'
            },
            {
              key: 'classification',
              label: '标签',
              type: 'souSelect',
              config: {
                options: strategyInfos
              }
            },
            {}
          ]
        },
        columns: [
          {
            key: 'articleType',
            title: '文章类型',
            width: '130px',
          },
          {
            key: 'title',
            title: '标题',
            width: '150px',
          },
          {
            key: 'classification',
            title: '分类',
            width: '130px',
          },
          {
            key: 'source',
            title: '来源',
            width: '130px',
          },
          {
            key: 'relatedCollegeName',
            title: '相关院校',
            width: '130px',
          },
          {
            key: 'createdDate',
            title: '创建时间',
            width: '130px',
            type: 'datetime'
          },
          {
            key: 'lastModifiedDate',
            title: '更新时间',
            width: '130px',
            type: 'datetime'
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '100px'
          }
        ],

        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        checkbox: true,
        actions: [
          {
            key: 'edit',
            text: '新建攻略资讯',
            type: 'form',
            permission: '/article/add',
            config: {
              color: 'primary',
              title: '新建攻略资讯',
              initUrl: '/article/getAllCountryAndColleges',
              submitUrl: "/article/add",
              submitText: '保存',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  isDisabled: (row, key) => {
                    return true
                  },
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'cover',
                  label: '封面',
                  required: true,
                  tips: "建议尺寸240x182 ；请上传大小不超过5mb的图片。",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/article/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 1000 * 5
                  }
                },
                {},
                {
                  key: 'articleType',
                  label: '文章类型',
                  type: 'radio',
                  config: {
                    initialValue: '攻略',
                    options: [
                      {
                        text: '攻略',
                        value: '攻略'
                      },
                      {
                        text: '资讯',
                        value: '资讯'
                      },
                    ]
                  }
                },
                {
                  key: 'classification',
                  label: '分类',
                  type: 'select',
                  required: true,
                  isAssociate: true,
                  config: {
                    options: strategys,
                    hidden({ selectedRows, form, initData }) {
                      if (form.articleType == '攻略') {
                        return false;
                      } else {
                        return true;
                      }
                    },
                  }
                },
                {
                  key: 'classification',
                  label: '分类',
                  type: 'select',
                  required: true,
                  isAssociate: true,
                  config: {
                    options: informations,
                    hidden({ selectedRows, form, initData }) {
                      if (form.articleType == '资讯') {
                        return false;
                      } else {
                        return true;
                      }
                    },
                  }
                },
                {
                  key: 'title',
                  label: '标题',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 30, message: '请尽量精简内容，输入30字以内' }
                    ]
                  }
                },
                {
                  key: 'source',
                  label: '来源',
                  type: 'text',
                  tips: "非原创文章请注明出处，未填写默认为网络",
                  config: {
                    rules: [
                      { min: 1, max: 10, message: '请尽量精简内容，输入10字以内' }
                    ]
                  }
                },
                {
                  key: 'relatedCountry',
                  label: '相关国家',
                  type: 'souSelect',
                  config: {
                    options: 'countryList'
                  }
                },
                {
                  key: 'relatedCollege',
                  label: '相关院校',
                  type: 'souSelect',
                  config: {
                    options: 'collegeList'
                  }
                },
                {
                  key: 'introduction',
                  label: '摘要',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 100, message: '请尽量精简内容，输入100字以内' }
                    ]
                  }
                },
                {
                  key: 'content',
                  label: '正文',
                  type: 'editor',
                  required: true,
                  config: {
                    uploadUrl: '/api/article/addUpload'
                  }
                },
              ]
            }
          },
          {
            key: 'edit',
            text: '删除',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/article/delete',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条文章，是否全部删除? `
            },
            config: {
              title: '删除成功',
              submitUrl: '/article/delete',
              submitText: '确认',
              controls: []
            }
          },
        ],
        /* 表格行操作 */
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/article/edit',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/article/edit/get",
              submitUrl: "/article/edit",
              submitText: '保存',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  isDisabled: (row, key) => {
                    return true
                  },
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'cover',
                  label: '封面',
                  required: true,
                  tips: "建议尺寸240x182 ；请上传大小不超过5mb的图片。",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/article/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 1000 * 5
                  }
                },
                {},
                {
                  key: 'articleType',
                  label: '文章类型',
                  type: 'radio',
                  config: {
                    // initialValue: '攻略',
                    
                    options: [
                      {
                        text: '攻略',
                        value: '攻略',
                        disabled: true,
                      },
                      {
                        text: '资讯',
                        value: '资讯',
                        disabled: true,
                      },
                    ]
                  }
                },
                {
                  key: 'classification',
                  label: '分类',
                  type: 'select',
                  required: true,
                  isAssociate: true,
                  config: {
                    options: strategys,
                    hidden({ selectedRows, form, initData }) {
                      if (form.articleType == '攻略') {
                        return false;
                      } else {
                        return true;
                      }
                    },
                  }
                },
                {
                  key: 'classification',
                  label: '分类',
                  type: 'select',
                  required: true,
                  isAssociate: true,
                  config: {
                    options: informations,
                    hidden({ selectedRows, form, initData }) {
                      if (form.articleType == '资讯') {
                        return false;
                      } else {
                        return true;
                      }
                    },
                  }
                },
                {
                  key: 'title',
                  label: '标题',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 30, message: '请尽量精简内容，输入30字以内' }
                    ]
                  }
                },
                {
                  key: 'source',
                  label: '来源',
                  type: 'text',
                  tips: "非原创文章请注明出处，未填写默认为网络",
                  config: {
                    rules: [
                      { min: 1, max: 10, message: '请尽量精简内容，输入10字以内' }
                    ]
                  }
                },
                {
                  key: 'relatedCountry',
                  label: '相关国家',
                  type: 'souSelect',
                  config: {
                    options: 'countryList'
                  }
                },
                {
                  key: 'relatedCollege',
                  label: '相关院校',
                  type: 'souSelect',
                  config: {
                    options: 'collegeList'
                  }
                },
                {
                  key: 'introduction',
                  label: '摘要',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 100, message: '请尽量精简内容，输入100字以内' }
                    ]
                  }
                },
                {
                  key: 'content',
                  label: '正文',
                  type: 'editor',
                  required: true,
                  config: {
                    uploadUrl: '/api/article/addUpload'
                  }
                },
              ]
            }
          }
        ]
      }
    }
  },
  async mounted() {
  },
  onUnmounted() {

  },
  computed: {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss"></style>